import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
require("./featureProducts.css");

let organicPowders = [
  {
    img_url:
    "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/bsc1-min-1024x683-1.jpg",
    name: "Bath Bar Combo",
    price: "450.00",
    quantity: "",
    slug:"bath-soap-combo"
  },
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/hoc1-min-1024x683-1.jpg",
    name: "Hair Oil Combo",
    price: "400.00",
    quantity: "",
    slug:"hair-oil-combo"
  },
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/bath-kit-min-1024x793-1.jpg",
    name: "Abhyanga Bath Kit",
    price: "1220.00",
    quantity: "",
    slug:"abhyanga-bath-kit"
  },
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/facepack-combo-min-1024x793-1.jpg",
    name: "Face Pack Combo",
    price: "750.00",
    quantity: "",
    slug:"face-pack-combo"
  }
];

class OrganicPowders extends React.Component {
  render() {
    return (
      <div className=" pad0">
        <div className="col-sm-12">
          <div className="row">
            {organicPowders.map((product, i) => {
               return (
                <div className="col-sm-3" key={i}>
                  <div className="featureProdDivisions">
                   <Link to={`/nativeproduct/${product.slug}/`} >
                    <div>
                      <img
                      style={{
                        width:"100%",
                        height: "200px",
                        objectFit: "cover"
                      }}
                        src={product.img_url}
                        className=" img-fluid"
                        alt="Native circle product"
                      />
                    </div>
                    <div className="featureProducts">
                      <p className="featureProdName">{product.name}</p>
                      <p className="featureProdQty">{product.quantity}</p>
                      <p className="featureProdPrice">
                        <span className="proddRupeeSymbol">&#8377;</span>
                        {product.price}
                      </p>
                    </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default OrganicPowders;
