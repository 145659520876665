import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
require("./featureProducts.css");

let personalCareProducts = [
  {
    img_url:
      "https://res.cloudinary.com/djoztpm50/image/upload/v1564056038/Native%20Circle/lip-balm.png",
    name: "Moringa Lip Balm",
    price: "150.00",
    quantity: "20g",
    slug: "moringa-lip-balm"
  },
  {
    img_url:
      "https://res.cloudinary.com/djoztpm50/image/upload/v1564056631/Native%20Circle/lotion.png",
    name: "Skin Care Lotion",
    price: "200.00",
    quantity: "60ml",
    slug:"skin-care-lotion"
  },
  {
    img_url:
      "https://res.cloudinary.com/djoztpm50/image/upload/v1564056639/Native%20Circle/neem-soap.png",
    name: "Neem Exfoliating Bath Soap",
    price: "150.00",
    quantity: "85g",
    slug:"neem-exfoliating-bath-soap"
  },
  {
    img_url:
      "https://res.cloudinary.com/djoztpm50/image/upload/v1564057127/Native%20Circle/manj-soap.png",
    name: "Manjishta Bath Soap",
    price: "200.00",
    quantity: "85g",
    slug:"manjishta-bath-soap"
  }
];

class PersonalCare extends React.Component {
  render() {
    return (
      <div className=" pad0">
        <div className="col-sm-12">
          <div className="row">
            {personalCareProducts.map((product, i) => {
              return (
                <div className="col-sm-3" key={i}>
                  <div className="featureProdDivisions">
                   <Link to={`/nativeproduct/${product.slug}/`} >
                    <div>
                      <img
                        src={product.img_url}
                        className=" img-fluid"
                        alt="Native circle product"
                      />
                    </div>
                    <div className="featureProducts">
                      <p className="featureProdName">{product.name}</p>
                      <p className="featureProdQty">{product.quantity}</p>
                      <p className="featureProdPrice">
                        <span className="proddRupeeSymbol">&#8377;</span>
                        {product.price}
                      </p>
                    </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalCare;
