import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ModalVideo from "react-modal-video";
require("./SocioEconomicImpact.css");

class EconomicImpact extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div className="economicsMain ">
        <div className="economicsVideoSec col-sm-4">
          <div>
            <img className="formersuccesspic" src={this.props.img} alt="" />
            <ModalVideo
              channel="youtube"
              isOpen={this.state.isOpen}
              videoId={this.props.videoid}
              // loop="1"
              // playlist = {this.props.videoid}
              onClose={() => this.setState({ isOpen: false })}
              
            />
            {/* <button onClick={this.openModal}>Open</button> */}
            <a onClick={this.openModal}>
              <img
                className="videoicon1"
                src="https://res.cloudinary.com/djoztpm50/image/upload/q_80/v1555081101/group-48121_frmfyh.svg"
              />
            </a>
          </div>
        </div>
        <div className="economicsInfo col-sm-8">
          <p className="subInfo">{this.props.member}</p>
          <h3 className="FormerBenefits">{this.props.heading}</h3>
          <p className="formerBenInfo">{this.props.content}</p>
        </div>
      </div>
    );
  }
}

export default EconomicImpact;
