import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
require("./featureProducts.css");

let forestryProducts = [
  {
    img_url:
    "https://res.cloudinary.com/djoztpm50/image/upload/v1565274095/2_1_-min_upzyrc.jpg",
    name: "Turmeric Powder",
    price: "150.00",
    quantity: "100g",
    slug:"turmeric-powder"
  },
  {
    img_url:
       "https://res.cloudinary.com/djoztpm50/image/upload/v1564056874/Native%20Circle/moringa-powder.png",
    name: "Moringa Powder",
    price: "150.00",
    quantity: "100g",
    slug:"moringa-powder"
  },
  {
    img_url: 
      "https://res.cloudinary.com/djoztpm50/image/upload/v1564056874/Native%20Circle/brahmi-powder.png",
    name: "Brahmi Powder",
    price: "150.00",
    quantity: "100g",
    slug:"brahmi-powder"
  },
  {
    img_url:
    "https://res.cloudinary.com/djoztpm50/image/upload/v1564056876/Native%20Circle/honey.png",
    name: "Wild Honey",
    price: "300.00",
    quantity: "300ml",
    slug:"wild-honey"
  }
];

class ForestryProducts extends React.Component {
  render() {
    return (
      <div className=" pad0">
        <div className="col-sm-12">
          <div className="row">
            {forestryProducts.map((product, i) => {
              return (
                <div className="col-sm-3" key={i}>
                  <div className="featureProdDivisions">
                   <Link to={`/nativeproduct/${product.slug}/`} >
                    <div>
                      <img
                        src={product.img_url}
                        className=" img-fluid"
                        alt="Native circle product"
                      />
                    </div>
                    <div className="featureProducts">
                      <p className="featureProdName">{product.name}</p>
                      <p className="featureProdQty">{product.quantity}</p>
                      <p className="featureProdPrice">
                        <span className="proddRupeeSymbol">&#8377;</span>
                        {product.price}
                      </p>
                    </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default ForestryProducts;
