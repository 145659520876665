import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import CartImg from "../images/shape.png";
import Axios from "axios";
require("./headerLight.css");
require("../css/responsive.scss");

var onLoadMainCatArray = [],
  onLoadCat = [];
var imgArray = [
  "https://res.cloudinary.com/djoztpm50/image/upload/v1563273958/Native%20Circle/fsc1-min_copy_2.png",
  "https://res.cloudinary.com/djoztpm50/image/upload/v1563275259/Native%20Circle/fsc1-min_copy.png",
  "https://res.cloudinary.com/djoztpm50/image/upload/v1563273958/Native%20Circle/fsc1-min.png"
];
var initialData = [];

class HeaderLight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "red",
      menu: false,
      menuColor: "menuStickyColor",
      cartLength: this.props.data,
      onLoadMainCatArray1: [],
      loginstatus: "",
      loginmail: "",
      loggedinname: "",
      mainCatV2 : [],
      allFilterData: {},
      catV2: []


    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidUpdate() {
    onLoadMainCatArray = JSON.parse(
      window.localStorage.getItem("onLoadMainCatArray")
    );
    onLoadCat = JSON.parse(window.localStorage.getItem("onLoadCat"));
  }

   // Comparing based on the property item
   compare_item(a, b){
    // a should come before b in the sorted order
    if(a.node.wordpress_id < b.node.wordpress_id){
            return -1;
    // a should come after b in the sorted order
    }else if(a.node.wordpress_id > b.node.wordpress_id){
            return 1;
    // and and b are the same
    }else{
            return 0;
    }
  }


  logout() {

    window.sessionStorage.clear();

    //  this.setState({logindisplay:"block"});
  }
  componentDidMount() {
    // window.localStorage.setItem("category", "allData");
    // window.localStorage.setItem("isSelected", "false");
    // window.localStorage.setItem("menuProduct", "");
    this.setState({
      loginstatus: window.sessionStorage.getItem("isLoggedIn"),
      loginmail: window.sessionStorage.getItem("email")
    });
    //alert(window.sessionStorage.getItem("email"));

    if (window.sessionStorage.getItem("email")) {
      Axios
        .post("https://dashboard.nativecircle.in/wp-json/api/login", { email: window.sessionStorage.getItem("email") })
        .then(response => {
          var name = (response.data.message[0].full_name).substr(0, 5) + '...'
          this.setState({
            //loggedinname:response.data.message[0].full_name
            loggedinname: name
          })
          // console.log(response);
        })
    }

    onLoadMainCatArray = [];
    window.addEventListener("scroll", this.listenScrollEvent);
    // this.headerActive();
    this.setState({ menuColor: "menuStickyColor" });
    // window.localStorage.setItem("category", "allData");
    // this.saveData();
    
    initialData.forEach(function (element) {
      // console.log(element)
      // alert(element.parent_element)
      if (
        element.node.parent_element == null &&
        onLoadMainCatArray.includes(element) == false
      ) {
        onLoadMainCatArray.push(element);
      }
    });
    let allFilterData = {};
    let mainCatV2 = JSON.parse(window.localStorage.getItem("mainCatV2"))
    let catV2 = JSON.parse(window.localStorage.getItem("catV2"))
    
    if (mainCatV2 != null) {
      mainCatV2.forEach(element => {
        allFilterData[element.node.wordpress_id] = []
      })
      mainCatV2.sort(this.compare_item)
    }

    if (catV2 != null) {
      catV2.forEach(element => {
        if (element.node.wordpress_parent != 0) {
          allFilterData[element.node.wordpress_parent].push(element.node)
        }
      })  
    }

    // console.log(catV2)
   
    // console.log(mainCatV2)
    this.setState({
      allFilterData: allFilterData,
      catV2: catV2,
      mainCatV2: mainCatV2
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ cartLength: nextProps });
  }

  listenScrollEvent = e => {
    if (window.scrollY > 150) {
      this.setState({
        headerClass: "headerSticky",
        displaySticky: "block",
        display: "none",
        navClassName: "navbar-toggleSticky",
        menuColor: "menuStickyColorOnScroll",
        menuStickySignup: "menuStickySignup",
        navbarClassName: "navbar-light",
        backgroundColor: "navbar-white "
      });
    } else {
      this.setState({
        headerClass: "headerbackgroundNew",
        displaySticky: "none",
        display: "block",
        navClassName: "navbar-toggle",
        menuColor: "menuStickyColor",
        menuStickySignup: "",
        navbarClassName: "navbar-dark",
        backgroundColor: "navbar-black "
      });
    }
  };

  mobilenoheader(headermobile) {
    window.localStorage.setItem("category", "allData");

    window.localStorage.setItem("menuCategory", "");

    window.localStorage.setItem("homePath", "");
    window.localStorage.setItem("MobileNumber", "");
    window.localStorage.setItem("headerPath", headermobile);
    // console.log(window.localStorage.getItem("headerPath"));
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  render() {
    const show = this.state.menu ? "show" : "";
    return (
      <div>
        <div className="headerLight">
          <nav className="navbar navbar-expand-lg navbar-light static-top ">
            <div className="container ">
              <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                <Link to="/">
                  <img
                    style={{
                      width:"54px",
                      height: "50px",
                      objectFit: "contain"
                    }}
                    className="NC-headerLogo-light"
                    id="desc-logo"
                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1630572214/Native%20Circle/logos/native_circle_logo-new.png"
                  />

                  <img
                    style={{
                      width:"54px",
                      height: "50px",
                      objectFit: "contain"
                    }}
                    className="NC-headerLogo-light"
                    id="mob-logo"
                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1630572214/Native%20Circle/logos/native_circle_logo-new.png"
                  />
                </Link>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  onClick={this.toggleMenu}
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>

                {
                  this.props.pathname == "/payment-checkout" ||
                    this.props.pathname == "/profile" ||
                    this.props.pathname == "/order_details" ||
                    this.props.pathname == "/paymentconfirmation" ||
                    this.props.pathname == "/shipping-policy" ||
                    this.props.pathname == "/terms-and-condition" ||
                    this.props.pathname == "/privacy-policy" ||
                    this.props.pathname == "/blog" ||
                    this.props.pathname == "/cancellationpolicy" ? (
                      ""
                    ) : (
                      <div className="mobileCart">
                        <Link className="nav-link" to="/myCart">
                          <div className="cartDesign">
                            <img className="cartImg" src={CartImg} />
                            <p className="cartTotalValue">{this.props.data} </p>
                          </div>
                        </Link>
                      </div>
                    )}
              </div>
              <div
                className={"collapse navbar-collapse navbar-white  " + show}
                id="navbarResponsive"
              >
                <div>
                  <ul className="navbar-nav responsive-product-menu">
                    <li className=" NC_native_Menu nav-item ">
                      <Link
                        className="nav-link"
                        to="/how-it-works/"
                        activestyle={{ color: "#F2BE53" }}
                      >
                        How it works
                      </Link>
                    </li>

                    <li className=" NC_native_Menu nav-item ">
                      <Link
                        className="nav-link"
                        to="/blog/"
                        activestyle={{ color: "#F2BE53" }}
                      >
                        Blogs
                      </Link>
                    </li>

                    <li className=" NC_native_Menu nav-item mobile-only">
                      <Link
                        className="nav-link"
                        to="/products/"
                        activestyle={{ color: "#F2BE53" }}
                      >
                        Products
                      </Link>
                    </li>

                    <li className="NC_native_Menu nav-item desktop-only">
                      <div className="dropdown orderdetailsProducts">
                        {" "}
                        {/*<div className="dropdown">*/}
                        {this.props.pathname == "/order_details" ? (<Link
                          activestyle={{ color: "#F2BE53" }}
                          style={{ paddingTop: "14px" }}
                          className="nav-link"
                          to="/products/"
                          onClick={() => this.mobilenoheader("headermobile")}
                        >
                          Products <i className="fa fa-caret-down" />
                        </Link>) : (<Link
                          activestyle={{ color: "#F2BE53" }}
                          className="nav-link"
                          to="/products/"
                          onClick={() => this.mobilenoheader("headermobile")}
                        >
                          Products <i className="fa fa-caret-down" />
                        </Link>)}


                        {/* <Link
                          activestyle={{ color: "#F2BE53" }}
                          className="nav-link"
                          to="/products/"
                          onClick={() => this.mobilenoheader("headermobile")}
                        >
                          Products <i className="fa fa-caret-down" />
                        </Link> */}

                        <div className="dropdown-content" id="productsDD">
                          <div className="col-sm-12">
                          {
                          this.state.allFilterData != null 
                            && this.state.mainCatV2 != null
                            && this.state.mainCatV2.length != 0
                            ? this.state.mainCatV2.map(( node , i) => {
                              var catWpId = node.node.wordpress_id;
                              var imgSrc = imgArray[i];
                              let filterData = this.state.allFilterData[catWpId];
                                return (
                                  <div
                                    className=" col-sm-4 agriFloatForestry"
                                    key={i}
                                  >
                                    {/* <img className="imgPad" src={imgSrc} /> */}

                                    <a
                                    className="agriPad30"
                                    href="/products/"
                                    onClick={() => {
                                      // window.localStorage.setItem(
                                      //   "category",
                                      //   [node.node.wordpress_id]
                                      // );
                                      // window.localStorage.setItem(
                                      //   "menuCategory",
                                      //   ["0"]
                                      // );
                                      // window.localStorage.setItem(
                                      //   "categoryId",
                                      //   [node.node.wordpress_id]
                                      // );
                                      window.localStorage.setItem(
                                        "mainCategoryId",
                                        node.node.wordpress_id
                                      );
                                    }}
                                  >
                                    {node.node.name}
                                  </a>
                                    <div>
                                      <ul className="agriProdList headerLig">
                                      {filterData != null 
                                        ? filterData.map(( subNode , i) => {
                                              return (
                                                <li
                                                  className="headerList"
                                                  key={i}
                                                >
                                                  <a
                                                  href="/products/"
                                                  onClick={() => {
                                                    // window.localStorage.setItem(
                                                    //   "category",
                                                    //   [
                                                    //     subNode.parent_element
                                                    //       .wordpress_id
                                                    //   ]
                                                    // );
                                                    // window.localStorage.setItem(
                                                    //   "menuCategory",
                                                    //   [subNode.wordpress_id]
                                                    // );
                                                    // window.localStorage.setItem(
                                                    //   "categoryId",
                                                    //   [
                                                    //     subNode.wordpress_id,
                                                    //     subNode.parent_element
                                                    //       .wordpress_id
                                                    //   ]
                                                    // );
                                                    window.localStorage.setItem(
                                                      "subCategoryId",
                                                      subNode.wordpress_id
                                                    );
                                                  }}
                                                >
                                                  {subNode.name}
                                                </a>
                                                </li>
                                              );
                                          })
                                          : null}
                                      </ul>
                                    </div>
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className=" NC_native_Menu nav-item">
                      <Link
                        className="nav-link"
                        to="/farmers/"
                        activestyle={{ color: "#F2BE53" }}
                      >
                        Workshops
                      </Link>
                    </li>

                    <li className=" native_Menu_Business NC_native_Menu nav-item">
                      <Link
                        className="nav-link NC-Business-nav"
                        to="/business/"
                        activestyle={{ color: "#F2BE53" }}
                      >
                        For Bulk-Orders
                      </Link>
                    </li>

                    <li className="NC_native_Menu nav-item">
                      <a
                        // className={this.state.menuColor}
                        className="nav-link"
                        href="https://medium.com/native-circle"
                        activestyle={{ color: "#F2BE53" }}
                        target="_blank"
                        style={{ color: "rgba(0, 0, 0, 0.5)" }}
                      >
                        Blog
                      </a>
                    </li>
                    <li className="NC_native_Menu nav-item">
                      <Link
                        className="nav-link"
                        to="/work-with-us/"
                        activestyle={{ color: "#F2BE53" }}
                      >
                        Join Us
                      </Link>
                    </li>
                    {this.state.loginstatus == "true" ? (
                      <div>
                        <li className="NC_native_Menu nav-item">

                          <div className="dropdown profile">

                            {/* <img src="https://res.cloudinary.com/djoztpm50/image/upload/c_scale,h_44,w_44/v1568376396/profile_new_zd6qdb.png"
                      class="profileimage" >
                      </img> */}
                            {/* <p >Welcome,{this.state.loggedinname}</p>
                       <i className="fa fa-caret-down" style={{color:"black",paddingLeft:"5px",fontSize:"20px"}} /> */}
                            {this.props.pathname == "/order_details" ? (
                              <Link
                                className="nav-link"
                                style={{ paddingTop: "14px" }}
                                activestyle={{ color: "#F2BE53" }}
                              >
                                {this.state.loggedinname} <i className="fa fa-caret-down" />

                                {/* <Text>Welcome,{this.state.loggedinname} </Text><i className="fa fa-caret-down" /> */}
                              </Link>
                            ) : (
                                <Link
                                  className="nav-link"
                                  activestyle={{ color: "#F2BE53" }}
                                >


                                  {this.state.loggedinname} <i className="fa fa-caret-down" />

                                  {/* <Text>Welcome,{this.state.loggedinname} </Text><i className="fa fa-caret-down" /> */}
                                </Link>
                              )}




                            <div className="dropdown-content profile_drop_down_content" >
                              <ul>
                                <li><a href="/profile/">Profile</a></li>
                                <li><a href="/order_details/">Order details</a></li>
                                <li> <a href="/" onClick={this.logout}>Logout</a></li>


                              </ul>

                            </div>

                          </div>
                        </li>
                      </div>
                    ) : (
                        <div>
                          <li className=" native_Menu_Business native_Menu nav-item">
                            <Link to="/login/">
                              <button className="loginbutton">
                                Login
                        </button>
                            </Link>
                          </li>
                        </div>
                      )}

                    {/* <li className=" NC_native_Menu nav-item">
                                        <a className="nav-link" href="#">
                                            Blog
                    </a>
                                    </li> */}
                  </ul>
                </div>
              </div>
              {
                this.props.pathname == "/payment-checkout" ||
                  this.props.pathname == "/profile" ||
                  this.props.pathname == "/order_details" ||
                  this.props.pathname == "/paymentconfirmation" ||
                  this.props.pathname == "/shipping-policy" ||
                  this.props.pathname == "/terms-and-condition" ||
                  this.props.pathname == "/privacy-policy" ||
                  this.props.pathname == "/blog" ||
                  this.props.pathname == "/cancellationpolicy" ? (
                    ""
                  ) : (
                    <div
                      className={"collapse navbar-collapse navbar-white  " + show}
                    >
                      <ul className="navbar-nav HideInMobileMycart">
                        <li className=" NC_native_Menu nav-item">
                          <Link
                            className="nav-link"
                            to="/myCart"
                            style={{ position: "relative", top: "20%" }}
                          >
                            <img className="cartImg" src={CartImg} />
                            <p className="cartTotal">{this.props.data} </p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default HeaderLight;
