import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ReactDOM from 'react-dom'
require('./formersuccess.css')


class blogcard extends React.Component {

    constructor() {
        super();
    }




    render() {
        return (
            <div className="formersuccessMain">
                <a
                    href={this.props.url}
                    target="_blank"
                >
                    <div>
                        <img className="formersuccesspic blogCardSizing" src={this.props.img} alt=""></img>

                    </div>


                    <div className="formersuccessInfo blogCardContent">
                        <h3 className="FormerBenefits">{this.props.heading}</h3>
                        <p className="formerBenInfo">{this.props.content}</p>
                    </div>
                </a>
            </div>
        );
    }
}

export default blogcard;

