import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
require("./featureProducts.css");

let newlyAddedProducts = [
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2021/09/Web-ToneFit.jpg",
    name: "Tone & Fit",
    price: "100",
    quantity: "10 Infusion Bags",
    slug: "tone-fit"
  },
  
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2021/09/Web-Beherb.jpg",
    name: "Beherb",
    price: "100",
    quantity: "10 Infusion Bags",
    slug:"beherb"
  },
  
  {
    img_url:
      // "http://dashboard.nativecircle.in/wp-content/uploads/2019/06/turmericpowder1-min.jpg",
      "https://dashboard.nativecircle.in/wp-content/uploads/2021/09/Web-Immunitea.jpg",
    name: "Immunitea",
    price: "100",
    quantity: "10 Infusion Bags",
    slug:"immunitea"
  }
];

class NewlyAddedProducts extends React.Component {
  render() {
    return (
      <div className=" pad0">
        <div className="col-sm-12">
          <div className="row" style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
            {newlyAddedProducts.map((product, i) => {
              return (
                <div className="col-sm-3" key={i}>
                  <div className="featureProdDivisions">
                   <Link to={`/nativeproduct/${product.slug}/`} >
                    <div>
                      <img
                      style={{
                        width:"100%",
                        height: "200px",
                        objectFit: "cover"
                      }}
                        src={product.img_url}
                        className=" img-fluid"
                        alt="Native circle product"
                        // style={{ height: "230px"}}
                      />
                    </div>
                    <div className="featureProducts">
                      <p className="featureProdName">{product.name}</p>
                      <p className="featureProdQty">{product.quantity}</p>
                      <p className="featureProdPrice">
                        <span className="proddRupeeSymbol">&#8377;</span>
                        {product.price}
                      </p>
                    </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default NewlyAddedProducts;
