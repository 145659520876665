import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
require("./featureProducts.css");

let edibleProducts = [
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/hs250-297x300-1.jpg",
    name: "Herbal Shampoo",
    price: "200.00",
    quantity: "250ml",
    slug: "herbal-shampoo-2"
  },
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/nes1-min-1024x793-3.jpg",
    name: "Neem Exfoliating Bath Soap",
    price: "150.00",
    quantity: "95g",
    slug: "neem-exfoliating-bath-soap"
  },
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/cs4-min-1024x683-3.jpg",
    name: "Activated Charcoal Bath Soap",
    price: "150.00",
    quantity: "100g",
    slug: "activated-charcoal-bath-soap"
  },
  {
    img_url:
      "https://dashboard.nativecircle.in/wp-content/uploads/2019/08/brhami-hair-oil-1-min-1024x683-3.jpg",
    name: "Brahmi Hair Oil",
    price: "200.00",
    quantity: "100ml",
    slug: "brahmi-hair-oil"
  }
];

class Edible extends React.Component {
  render() {
    return (
      <div className=" pad0">
        <div className="col-sm-12">
          <div className="row">
            {edibleProducts.map((product, i) => {
              return (
                <div className="col-sm-3" key={i}>
                  <div className="featureProdDivisions">
                   <Link to={`/nativeproduct/${product.slug}/`} >
                    <div>
                      <img
                        style={{
                          width:"100%",
                          height: "200px",
                          objectFit: "cover"
                        }}
                        src={product.img_url}
                        className=" img-fluid"
                        alt="Native circle product"
                      />
                    </div>
                    <div className="featureProducts">
                      <p className="featureProdName">{product.name}</p>
                      <p className="featureProdQty">{product.quantity}</p>
                      <p className="featureProdPrice">
                        <span className="proddRupeeSymbol">&#8377;</span>
                        {product.price}
                      </p>
                    </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Edible;
