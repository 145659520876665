import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import FormerSuccess from "../components/farmersuccess";
import Blogcard from "../components/blogcard";
import EconomicImpact from "../components/socioEconomicImpact";
import Header from "../components/header";
import Footer from "../components/footer";
import HeaderLight from "../components/headerLight";
import ReactDOM from "react-dom";
import axios from "axios";
import { Tabs, notification } from "antd";
import SweetAlert from "sweetalert-react";
import "../../node_modules/react-modal-video/css/modal-video.min.css";
import ModalVideo from "react-modal-video";
import ControlledCarousel from "../components/carousel";
import Healthcare from "../components/Healthcare";
import NewlyAddedProducts from "../components/NewlyAddedProducts";
import Edible from "../components/Edible";
import PersonalCare from "../components/PersonalCare";
import OrganicPowders from "../components/OrganicPowders";
import ProcessedProducts from "../components/ProcessedProducts";
import OrganicSpices from "../components/OrganicSpices";
// import DonutChart from "react-donut-chart";


require("../css/home.css");
require("../css/responsive.scss");
require("sweetalert/dist/sweetalert.css");

const TabPane = Tabs.TabPane;



// const divStyle = {
//   scrollBehavior: "unset",
//   color: "black"

// };

var onLoadMainCatArray = [];
var mainCatV2 = [];

class HomePage extends React.Component {
  constructor() {
    super();
    this.state = {
      color: "red",
      // visible: false,
      loading: false,
      screenWidth: "",
      headerClass: "headerhideonscroll",
      headerClass1: "headerhideonscroll1",
      display: "block",
      displaySticky: "none",
      navClassName: "navbar-toggle",
      menuColor: "",
      menuStickySignup: "",
      featureProducts: "",
      email: "",
      displayEmail: "none",
      displayEmailInvalid: "none",
      displaySuccessMessage: "none",
      blog1: "",
      blog1Image: "",
      blog2: "",
      blog2Image: "",
      blog3: "",
      blog3Image: "",
      mainCatV2: []
    };

    this.saveCatdata = this.saveCatdata.bind(this);
  }

    // Comparing based on the property item
 compare_item(a, b){
  // a should come before b in the sorted order
  if(a.node.wordpress_id < b.node.wordpress_id){
          return -1;
  // a should come after b in the sorted order
  }else if(a.node.wordpress_id > b.node.wordpress_id){
          return 1;
  // and and b are the same
  }else{
          return 0;
  }
}

  componentDidMount() {
    var blogData = this.props.data.allWordpressWpBlog.edges;
    this.setState({
      blog1: blogData[0].node.acf,
      blog1Image: blogData[0].node.acf.mediumimage.source_url,
      blog2: blogData[1].node.acf,
      blog2Image: blogData[1].node.acf.mediumimage.source_url,
      blog3: blogData[2].node.acf,
      blog3Image: blogData[2].node.acf.mediumimage.source_url
    })
    var catLocalArray = [];
    const data = this.props.data;
    window.localStorage.setItem("category", "allData");

    window.localStorage.setItem("menuCategory", "");

    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    }

    // data.allWordpressWpProductCategories.edges.forEach(x => {
    //   console.log(x.node.name, x.node.parent_element)
    // })

    catLocalArray = JSON.stringify(data.allWordpressWpProductCategories.edges);
    window.localStorage.setItem("categorylocalArray", catLocalArray);

    // window.addEventListener("scroll", this.listenScrollEvent);

    this.saveCatdata(catLocalArray);
  }

  saveCatdata(catdata) {

    var onLoadCat = this.props.data.allWordpressWpProductCategories.edges;
    var catV2 = this.props.data.allWordpressWpProductCategoryV2.edges;
    console.log("ONLoadcat");
    // onLoadCat.forEach(x => {
    //   console.log(x.node.name, x.node.parent_element)
    // });
    window.localStorage.setItem("onLoadCat", JSON.stringify(onLoadCat));
    window.localStorage.setItem("catV2", JSON.stringify(catV2));
    onLoadCat.forEach(function (element) {
      // console.log(element)
      // alert(element.parent_element)
      if (
        element.node.parent_element == null &&
        onLoadMainCatArray.includes(element) == false
      ) {
        // console.log("yoyo", element.node.name)
        onLoadMainCatArray.push(element);
      }
    });
    mainCatV2 = [];
    catV2.forEach(function (element) {
      // console.log(element)
      // alert(element.parent_element)
      if (
        element.node.wordpress_parent == 0 
      ) {
        mainCatV2.push(element);
      }
    });
    mainCatV2.sort(this.compare_item)
    window.localStorage.setItem(
      "mainCatV2",
      JSON.stringify(mainCatV2)
    );
    this.setState({mainCatV2: mainCatV2})
  }

  callback(key) {
    // console.log(key);
  }

  openNotificationWithIcon = type => {
    notification[type]({
      message: 'Thank you for contacting us!',
      description:
        ' We will get in touch with you soon.',
      duration: 5
    });
  };

  handleSubmit() {
    var _this = this;
    var bodyFormData = new FormData();
    var email = document.getElementById("email").value;
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.email == "" || !pattern.test(email)) {
      this.validateEmail();
    }
    else {
      bodyFormData.set("email", this.state.email);
      bodyFormData.set("formID", 1231);
      axios
        .post(
          "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/1231/feedback",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(response => {
          console.log(response.status);
          // document.getElementById("email").reset();
          if (response.status == 200 || response.status == 201) {

            this.openNotificationWithIcon('success')
            // alert("success! mail sent")
            _this.setState({
              showAlert: true,
              // loading: false,
              // displaySuccessMessage: "block",
              email: ""
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

  }

  validateEmail() {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    if (email == "" || email == undefined) {
      this.setState({ displayEmail: "block", email: "" });
    } else {
      this.setState({ displayEmail: "none", email: email });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });
      }
    }
  }

  //  handleOK(event){
  //     //....swal stuff
  //     event.preventDefault();
  //  }

  render() {

    return (
      <div id="homepage" >
        <div id="headersh">
          <div>
            <Header mainCatV2={this.state.mainCatV2}/>
          </div>
        </div>

        <div className="homePage">
          <div className="content">
            <ControlledCarousel />
          </div>
        </div>

        {/*
          Disabling products 
         */}
        {/* 
        <div style={{paddingTop: "5%"}}>
          <div className=" featureContainer">
            <h5 className="featureProdHeader"> Featured Products</h5>
            <div className="col-sm-12 pad0 featureProduct" id="homeTabID">
              <Tabs defaultActiveKey="1" onChange={this.callback()}>
                <TabPane className="homeTabClass" tab="Edible Organics" key="1">
                  <Healthcare />
                </TabPane>
                <TabPane className="homeTabClass" tab="Personal Care" key="2">
                  <Edible />
                </TabPane>
                <TabPane className="homeTabClass" tab="Combos" key="4">
                  <OrganicPowders />
                </TabPane>
              </Tabs>
            </div>

            <div className=" btnFeatureProd">
              <Link to="/products">
                <button className="otherButtons">
                  Shop now{" "}
                  <img
                    className="watchVideoIcon"
                    src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                  />
                </button>
              </Link>
            </div>
          </div>
        </div> */}

        {/* <div style={{paddingTop: "5%"}}>
          <div className=" featureContainer">
            <h5 className="featureProdHeader"> New Arrivals</h5>
            <div className="col-sm-12 pad0 featureProduct" id="homeTabID">
              <NewlyAddedProducts />
            </div>

            <div className=" btnFeatureProd">
              <Link to="/products">
                <button className="otherButtons">
                  Shop now{" "}
                  <img
                    className="watchVideoIcon"
                    src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                  />
                </button>
              </Link>
            </div>
          </div>
        </div> */}

        {/* sajith */}

        <div className="col-sm-12 pad0 farmerSuccess NativeCirclePicksFold" id="farmerSuccess">
          <div className="container">
            <div className="col-sm-12 pad0">
              <h5 className="connectFarmerHead">Native Circle Picks</h5>

              <div className="row">
                <div className="col-sm-4">
                  <Blogcard
                    url={this.state.blog3.mediumurl}
                    img={this.state.blog3Image}
                    heading={this.state.blog3.mediumheading}
                    content={this.state.blog3.shortdescription}
                  />
                </div>

                <div className="col-sm-4">
                  <Blogcard
                    url={this.state.blog2.mediumurl}
                    img={this.state.blog2Image}
                    heading={this.state.blog2.mediumheading}
                    content={this.state.blog2.shortdescription}
                  />
                </div>

                <div className="col-sm-4">
                  <Blogcard
                    url={this.state.blog1.mediumurl}
                    img={this.state.blog1Image}
                    heading={this.state.blog1.mediumheading}
                    content={this.state.blog1.shortdescription}
                  />
                </div>
                <div className="col-sm-12 btnMainCheckout">
                  <a
                    href="https://medium.com/native-circle"
                    target="_blank"
                  >
                    <button className="otherButtons">
                      Read more{" "}
                      <img
                        className="watchVideoIcon"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div>



          <div className="col-sm-12 pad0 farmerSuccess" id="farmerSuccess">
            <div className="container">
              <div className="col-sm-12 pad0">
                <h5 className="connectFarmerHead">Inspiring Personalities</h5>

                <div className="row">
                  <div className="col-sm-4">
                    <FormerSuccess
                      videoid="Y2ALVEgJWNA"
                      img="https://res.cloudinary.com/djoztpm50/image/upload/v1565176734/2B-min_zdvkj1.png"
                      heading="Varthuru Narayana Reddy"
                      content="The natural farmer who revolutionized methods and teachings of natural farming."
                    />
                  </div>

                  <div className="col-sm-4">
                    <FormerSuccess
                      videoid="dvHd46NR-DU"
                      img="https://res.cloudinary.com/djoztpm50/image/upload/v1565176742/2C-min_duxlob.png"
                      heading="Lakshmi Lokur"
                      content="Natural farmer based in Bailahongala who almost single handedly manages her farm and markets her produces."
                    />
                  </div>

                  <div className="col-sm-4">
                    <FormerSuccess
                      videoid="WLy4aG7Do-o"
                      img="https://res.cloudinary.com/djoztpm50/image/upload/v1565176724/2A-min_igwdfp.png"
                      heading="N D Hegde"
                      content="Pepper farmer from Kumta who is famous for his pepper grafting techniques."
                    />
                  </div>
                  <div className="col-sm-12 btnMainCheckout">
                    <a
                      href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                      target="_blank"
                    >
                      <button className="otherButtons">
                        Check out for more{" "}
                        <img
                          className="watchVideoIcon"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                        />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>




          {/* <div>
            <div className=" featureContainer">
              <h5 className="featureProdHeader"> Featured Products</h5>
              <div className="col-sm-12 pad0 featureProduct" id="homeTabID">
                <Tabs defaultActiveKey="1" onChange={this.callback()}>
                  <TabPane className="homeTabClass" tab="Health Care" key="1">
                    <Healthcare />
                  </TabPane>
                  <TabPane className="homeTabClass" tab="Edible" key="2">
                    <Edible />
                  </TabPane>
                  <TabPane className="homeTabClass" tab="Personal Care" key="3">
                    <PersonalCare />
                  </TabPane>
                  <TabPane className="homeTabClass" tab="Organic Powders" key="4">
                    <OrganicPowders />
                  </TabPane>
                  <TabPane className="homeTabClass" tab="Organic Spices" key="5">
                    <OrganicSpices />
                  </TabPane>
                  <TabPane
                    className="homeTabClass"
                    tab="Processed Products"
                    key="6"
                  >
                    <ProcessedProducts />
                  </TabPane>
                </Tabs>
              </div>

              <div className=" btnFeatureProd">
                <Link to="/products">
                  <button className="otherButtons">
                    Shop now{" "}
                    <img
                      className="watchVideoIcon"
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div> */}





          <div className="row economicImact">
            <div className="container economicContainer">
              <div className="col-sm-12 pad0">
                <h5 className="economicSecHeader phaseAlignCenter"> Socio-Economic Impact</h5>
                <p className="pWhite phaseAlignCenter">
                  <b>
                    Sustainable Practices | ‎Ecological Intensification | ‎Genetic
                    Intensification
                </b>{" "}
                  <br />
                  <br />
                  Native Circle aims to complete the full circle of organic life
                  cycle – from farm to the table. Ecologically responsible
                  practices, Sustainable methods, farming support system, and a
                  transparent value chain creates equal opportunities for farmers
                  to consumers.
              </p>
                <div className="socioImpactPadding">

                  {/* old socio economic impact starts here */}
                  {/* <div className="col-sm-8 alignMap">
                    <div className="row">
                      <div className="socioImageWidth">
                        <EconomicImpact
                          videoid="WLy4aG7Do-o"
                          img="https://res.cloudinary.com/djoztpm50/image/upload/v1566469230/socio-home-min_lsrjof.jpg"
                          member="Native Circle member"
                          heading="Narayana Hegde, Kumta, Uttara Kannada"
                          content="Sri Narayana Hegde (N D Hegde), Kumta is a progressive farmer. Hegde follows integrated farming method and grows black pepper and areca as main produces."
                        />
                      </div>

                      <div className="socioImageWidth impactPad">
                        <EconomicImpact
                          videoid="EdMzTgfmcks"
                          img="https://res.cloudinary.com/djoztpm50/image/upload/v1566469399/socio-home2-min_lvqzjm.jpg"
                          member="Native Circle member"
                          heading="Muttanna Poojar, Kammanahalli, Hanagal"
                          content="The story of a shepherd who left his nomadic lifestyle to become a successful farmer."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 alignMap">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1556885624/map-min_htfljp.png" />
                  </div> */}
                  {/* old socio economic impact ends here */}





                  {/* new socio economic impact starts here */}
                  <div className="row">
                    <div className="col-sm-4">
                      <FormerSuccess
                        videoid="tpCdwZks3xc"
                        img="https://res.cloudinary.com/dktnqgcmq/image/upload/v1584435869/PhaseII/harishgowda2-min_urgdgr.png"
                        heading="Harish Gowda, Hassan"
                        content="A young enterprising Mechanical Engineer, who is successfully running a Virgin Coconut Oil production unit called Essential Nectar Pvt Ltd."
                      />
                    </div>

                    <div className="col-sm-4">
                      <FormerSuccess
                        videoid="LbpIBgWpWR8"
                        img="https://res.cloudinary.com/dktnqgcmq/image/upload/v1584434003/PhaseII/muttannapujar-min_w7yhi5.png"
                        heading="Muttanna Poojar, Hanagal"
                        content="A Shepherd who became a self made successful natural farmer by purchasing a barren land in hanagal."
                      />
                    </div>

                    <div className="col-sm-4">
                      <FormerSuccess
                        videoid="RW0ry4244vE"
                        img="https://res.cloudinary.com/dktnqgcmq/image/upload/v1584433490/PhaseII/ashoksocioeconomic-min_rf8d83.png"
                        heading="Ashok Kumar, Kolar"
                        content="A lecturer-turned-farmer Ashok Kumar, converting his dry land into a productive one is the day-to-day job."
                      />
                    </div>
                    <div className="col-sm-12 btnMainCheckout socioeconomicmore">
                      <a
                        href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                        target="_blank"
                      >
                        <button className="otherButtons">
                          Check out for more{" "}
                          <img
                            className="watchVideoIcon"
                            src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                          />
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* new socio economic impact ends here */}



                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="col-sm-12  ">
              <div className="commitmentBackgroundImg pad0">
                <div className="row commitmentTopPadding">
                  <div className="col-sm-4 commitmentFirstSec">
                    <p className="commitmentMain">Our Commitment </p>

                    <p className="commitmentSub">HONEST PRICING AND DELIVERY </p>
                    <p className="commitmentContent">
                      The raw materials used for all the Native Circle products of
                      travel far from the interiors of Karnataka, so when you buy
                      a product you are also touching the lives of all those who
                    joined hands to create that product.{" "}
                    </p>
                  </div>

                  <div className="col-sm-8 commitmentSecondSec">
                    <div className="row">
                      <div className="col-sm-4 commitmentdiv">
                        <div className="commitmentDivisions">
                          <img src="https://res.cloudinary.com/djoztpm50/image/upload/q_80/v1554392411/Native%20Circle/group-64.svg" />
                          <h6 className="commitmentHeading">On time Delivery</h6>

                          <p>Assured on-schedule delivery</p>
                        </div>
                      </div>
                      <div className="col-sm-4 commitmentdiv">
                        <div className="commitmentDivisions">
                          <img src="https://res.cloudinary.com/djoztpm50/image/upload/q_80/v1554392411/Native%20Circle/group-65.svg" />
                          <h6 className="commitmentHeading">
                            Transparent pricing
                        </h6>

                          <p>Profit sharing explained</p>
                        </div>
                      </div>
                      <div className="col-sm-4 commitmentdiv">
                        <div className="commitmentDivisions">
                          <img src="https://res.cloudinary.com/djoztpm50/image/upload/q_80/v1554392411/Native%20Circle/group-66.svg" />
                          <h6 className="commitmentHeading">Value for money</h6>

                          <p>Compelling value proposition</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="col-sm-12  pad0">
              <div className="commitmentBackgroundImg1 pad0">
                <div className="row commitmentTopPadding-cng-nm">
                  <div className="col-sm-7 commitmentFirstSec">
                    <p className="never-miss-updtd">
                      Never miss the latest updates{" "}
                    </p>
                    {/*  <p className="nvr-prg-lpr">
                    Lorem ispum dolar sit amet lip sudun lip alit dem
                  </p>*/}
                  </div>

                  <div className="col-sm-5 inpt-mlr-trm" >
                    <div className="row">
                      <input
                        type="email"
                        id="email"
                        value={this.state.email}
                        placeholder="Enter your email id"
                        className="plc-nws-lttr"
                        onChange={() => this.validateEmail()}
                      />

                      <button class="nws-btn-snds"
                        onClick={() => this.handleSubmit()}
                      >
                        {" "}
                        <img
                          class="snds-ntr-ns-lks"
                          src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                        />
                      </button>
                    </div>

                    <p
                      style={{
                        display: this.state.displayEmail,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                   </p>


                    <p
                      style={{
                        display: this.state.displayEmailInvalid,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      The entered email id is not valid
                </p>

                    {/* <p
                        style={{
                          display: this.state.displaySuccessMessage,
                          color: "green",
                          marginTop: "10px"
                        }}
                      >
                       Thank you for contacting us! <br></br>
                       We will get in touch with you soon.
                </p> */}
                    {/* </div> */}

                    {/* <SweetAlert

                      show={this.state.showAlert}
                      title="Thank you for contacting us!"
                      text="We will get in touch with you soon."
                      onConfirm={() => this.setState({ showAlert: false })}
                    // onClick={this.handleOK.bind(this)}
                    /> */}

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>



        <Footer />
      </div>
    );
  }
}
export default HomePage;

export const pageQuery11 = graphql`
  query nativeproductsQuery11 {
    allWordpressWpProductCategories { 
      edges {
        node {
          id
          wordpress_id
          name
          wordpress_parent
          parent_element {
            id
            name
            wordpress_id
          }
        }
      }
    }

    allWordpressWpProductCategoryV2 {
      totalCount
      edges {
        node {
          name
          id
          wordpress_id
          wordpress_parent
          slug
        }
      }
    }

    allWordpressWpBlog(limit: 3) {
      edges {
        node {
          acf {
            mediumheading
            mediumurl
            shortdescription
            mediumimage {
              source_url
            }
          }
        }
      }
    }



  }
`;
