import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
require("./featureProducts.css");

let organicSpices = [
  {
    img_url:
    "https://res.cloudinary.com/djoztpm50/image/upload/v1565274095/2_1_-min_upzyrc.jpg",
    name: "Turmeric Powder",
    price: "150.00",
    quantity: "100g",
    slug:"turmeric-powder"
  },
  {
    img_url:
      "https://res.cloudinary.com/djoztpm50/image/upload/v1565176856/3B-min_h3dcke.png",
    name: "Tulsi Powder",
    price: "150.00",
    quantity: "100g",
    slug:"tulsi-powder"
  },
 
  {
    img_url:
      "https://res.cloudinary.com/djoztpm50/image/upload/v1564056871/Native%20Circle/black-pepper.png",
    name: "Black Pepper",
    price: "140.00",
    quantity: "100g",
    slug:"black-pepper"
  },
  {
    img_url:
      "https://res.cloudinary.com/djoztpm50/image/upload/v1564056878/Native%20Circle/white-pepper.png",
    name: "White Pepper",
    price: "160.00",
    quantity: "100g",
    slug:"white-pepper"
  }
];

class OrganicSpices extends React.Component {
  render() {
    return (
      <div className=" pad0">
        <div className="col-sm-12">
          <div className="row">
            {organicSpices.map((product, i) => {
              return (
                <div className="col-sm-3" key={i}>
                  <div className="featureProdDivisions">
                   <Link to={`/nativeproduct/${product.slug}/`} >
                    <div>
                      <img
                        src={product.img_url}
                        className=" img-fluid"
                        alt="Native circle product"
                      />
                    </div>
                    <div className="featureProducts">
                      <p className="featureProdName">{product.name}</p>
                      <p className="featureProdQty">{product.quantity}</p>
                      <p className="featureProdPrice">
                        <span className="proddRupeeSymbol">&#8377;</span>
                        {product.price}
                      </p>
                    </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default OrganicSpices;
