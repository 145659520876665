import React from "react";
import ReactDOM from "react-dom";
import Carousel from "react-bootstrap/Carousel";
// import '../../node_modules/react-modal-video/css/modal-video.min.css';
// import ModalVideo from 'react-modal-video';\
// import slider1 from "../images/slider_1.jpg";
// import slider2 from "../images/slider_2.jpg";
// import slider3 from "../images/slider_3.jpg";

var slider1 =
  "https://res.cloudinary.com/dktnqgcmq/image/upload/v1596831545/Banner_1_lmjtu0.jpg";
var slider11 =
  "https://res.cloudinary.com/dktnqgcmq/image/upload/v1594201905/mobile_3_copy_zulpgs.png";
var slider2 =
  "https://res.cloudinary.com/dktnqgcmq/image/upload/v1596831545/banner_2_c0frwg.jpg";
var slider22 =
  "https://res.cloudinary.com/dktnqgcmq/image/upload/v1594201923/Banner_2_mprg7m.png";
var slider3 =
  "https://res.cloudinary.com/dktnqgcmq/image/upload/v1596831546/Banner_3_su60si.jpg";
var slider33 =
  "https://res.cloudinary.com/dktnqgcmq/image/upload/v1594201905/mobile_2_copy_iz3m46.png";

require("../css/responsive.scss");

class ControlledCarousel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null
    };
  }

  handleSelect(selectedIndex, e) {
    if (e && e != null) {
      this.setState({
        index: selectedIndex,
        direction: e.direction
      });
    }
  }

  render() {
    const { index, direction } = this.state;

    return (
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
        interval={5000}
        pauseOnHover={false}
        className="CarouselDiv"
        controls={true}
        indicators={false}
        style={{
            height: "100%",
            position: "relative"
        }}
      // activeIndex={3}
      >
        <Carousel.Item
          style={{
            height: "100%"
          }}
        >
          <img
            className="d-block w-100 banner-img"
            id="desktopView"
            // src="https://res.cloudinary.com/djoztpm50/image/upload/v1557395386/Native%20Circle/home-min_bjfxlr-min.png"
            src={slider1}
            alt="First slide"
          />

          <img
            className="d-block w-100 banner-img"
            id="mobileView"
            // src="https://res.cloudinary.com/djoztpm50/image/upload/v1557395386/Native%20Circle/home-min_bjfxlr-min.png"
            src={slider11}
            alt="First slide"
          />
          <Carousel.Caption className="carousel-caption1">
            {/* <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}

            <div className="col-md-12 mainContent ">
              <text>The full circle of organic lifestyle</text>
            </div>
            <div className="col-md-12 subContent ">
              <text>
                Pure Products | Ethical Practices | Ecological Awareness
              </text>
            </div>

            <div className="col-sm-12">
              <div className=" colPadding  col-md-12 row">
                <div className="homeBannerButton col-sm-12 col-md-3 pad0">
                  <a href="#farmerSuccess" onClick={this.smoothScroll}>
                    <div className="section1 col-sm-12">
                      <div className="sub1 align col-sm-10">
                        <text>Read farmer stories</text>
                      </div>
                      <div className="sub2 align col-sm-2">&#x2192;</div>
                    </div>
                  </a>
                </div>

                <div className="homeBannerButton col-sm-12 col-md-3 pad0">
                  <a href="/farmers">
                    <div className="section2 col-sm-12">
                      <div className="sub1 align col-sm-10">
                        <text>Join the movement as a farmer</text>
                      </div>
                      <div className="sub2 align col-sm-2">&#x2192;</div>
                    </div>
                  </a>
                </div>

                <div className="homeBannerButton col-sm-12 col-md-3 pad0">
                  <a href="/business">
                    <div className="section2 col-sm-12">
                      <div className="sub1 align col-sm-10">
                        <text>Join the movement as a consumer</text>
                      </div>
                      <div className="sub2 align col-sm-2">&#x2192;</div>
                    </div>
                  </a>
                </div>

                <div className="homeBannerButton  col-sm-12 col-md-2 pad0">
                  <a href="/how-it-works">
                    <div className="section1 col-sm-12">
                      <div className="sub1 align col-sm-10">
                        <text>See how it works</text>
                      </div>
                      <div className="sub2 align col-sm-2">&#x2192;</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="row homePage_imgBanner col-sm-12">
              <div className="col-sm-4 col-md-1 col-4 ">
                <img
                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1551166738/Native%20Circle/eminence-organic-skin-care-cofa-logo-us-copy-2.png"
                  className="img-fluid"
                  alt="Responsive image"
                />
              </div>
              <div className="col-sm-4 col-md-1 col-4">
                <img
                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1551168232/Native%20Circle/group-2.png"
                  className="img-fluid"
                  alt="Responsive image"
                />
              </div>
              <div className="col-sm-4 col-md-1 col-4">
                <img
                  src="https://res.cloudinary.com/djoztpm50/image/upload/v1551168347/Native%20Circle/group-37.png"
                  className="img-fluid"
                  alt="Responsive image"
                />
              </div>
            </div> */}
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item
          style={{
            height: "100%"
          }}
        >
          <img
            className="d-block w-100 banner-img"
            id="desktopView1"
            // src="https://res.cloudinary.com/djoztpm50/image/upload/v1557395386/Native%20Circle/howitworks-min_l0p2hd-min.png"
            src={slider2}
            alt="Second slide"
          />

          <img
            className="d-block w-100 banner-img"
            id="mobileView1"
            // src="https://res.cloudinary.com/djoztpm50/image/upload/v1557395386/Native%20Circle/home-min_bjfxlr-min.png"
            src={slider22}
            alt="Second slide"
          />
          <Carousel.Caption className="carousel-caption2">
            <div className="col-md-12 mainContent ">
              <text>Bridging the gaps between producers and consumers</text>
            </div>
            <div className="col-md-12 subContent ">
              <text>
                Empowered Producers | Informed Customers
              </text>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item
          style={{
            height: "100%"
          }}
        >
          <img
            className="d-block w-100 banner-img"
            id="desktopView2"
            // src="https://res.cloudinary.com/djoztpm50/image/upload/v1557395386/Native%20Circle/farmers-min_jm3rpc-min.png"
            src={slider3}
            alt="Third slide"
          />

          <img
            className="d-block w-100 banner-img"
            id="mobileView2"
            // src="https://res.cloudinary.com/djoztpm50/image/upload/v1557395386/Native%20Circle/home-min_bjfxlr-min.png"
            src={slider33}
            alt="Third slide"
          />

          <Carousel.Caption className="carousel-caption3">
            <div className="col-sm-12">
              {/* <h3 className="forfarmerHead">Join your hands as a farmer <br /> and expand the circle</h3> */}
              <div className="col-md-12 mainContent">
                <text>Expand the circle</text>
              </div>
              <div className="col-md-12 subContent">
                <text>Join the circle and grow together</text>
              </div>

              <div className="col-sm-6 farmerPoints">
                {/* <div className="col-sm-12">
                  <div className="row"> */}
                <div
                  className="farmerbtnMain">
                  {/* <button className="downloadfarmer" href="">Download the App <img className="iconCheckout" src="https://res.cloudinary.com/djoztpm50/image/upload/v1551253843/Native%20Circle/path-down.png"/></button> */}

                  <a
                    href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                    target="_blank"
                  >
                    <button className="watchVideoBanner">
                      Watch videos{" "}
                      <img
                        className="watchVideoArr"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551253847/Native%20Circle/path-video.png"
                      />
                    </button>
                  </a>
                </div>
                {/* </div>
                </div> */}
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default ControlledCarousel;
